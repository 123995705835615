<template>
  <div id="Notice">
    <!-- <div class="notice_top">
      <img src="@/assets/image/logo.png" alt="" />
      <p>无纸化模拟机考考试</p>
    </div> -->
    <div class="testnotice">
      <h2>{{ name }}</h2>
      <div>
        <h4>考试须知</h4>
        <p>
          一、西知网西班牙语无纸化模拟机考系统，本考试试题一律在计算机上直接作答。
        </p>
        <p>
          二、答题界面：屏幕左上方为考试剩余时间，结束考试/交卷按钮，左下方为答题记录板；屏幕中间为考试题型分数及试题内容，屏幕右侧为答题区。
        </p>
        <p>
          三、点击‘交卷’按钮可提交试卷。考试结束时间到达时，系统自动为所有未交卷的考生交卷。考试期间如遇特殊情况需要离开一段时间，可点击暂停按钮，稍后再继续答题。
        </p>
        <p>
          四、答题时，考生使用鼠标在计算机答题界面上点击试题答案备选项中的正确选项即可。如需修改答案，单项选择题、判断题只需用鼠标点击其他备选项；多项选择题、不定项选择题需用鼠标点击已选择的备选项，取消原答题记录，再重新选择答案。
        </p>
        <p>
          五、若对当前选项不确定，可点击“标记”前的选择框进行标记，方便回看该题；用鼠标再次点击该选择框可以取消本题标记。被标记的试题，会在试题列表里的题号右上角以橙色小三角的形式进行显示。未作答的试题，在题目列表里以灰色方框进行显示，显示为灰色方框的题目在作答后自动填充为红色方框。
        </p>
        <p>
          六、在题目内容较多的情况下，请使用【材料全屏】鼠标拖动调整内容区域，答题区可上下滑动的纵向滚动条查看全部内容，以免遗漏信息。
        </p>
        <p>
          七、考试过程中，如遇到与电脑系统兼容、使用体验等问题无法正常使用系统，导致答题异常，请向我们客服人员反馈，由技术保障人员进行优化。
        </p>
        <div class="time" v-show="play">
          <van-count-down
            :time="time"
            @finish="finish"
            ref="countDown"
            :auto-start="false"
          >
            <template #default="timeData">
              距离考试开始还有
              <span class="block">0{{ timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="block">0{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">0{{ timeData.seconds }}</span>
            </template>
          </van-count-down>

          <p>倒计时结束后系统自动进入答题界面</p>
        </div>
        <div class="Btn">
          <el-button
            class="login-btn"
            @click="loginBtn"
            :disabled="!checkBool"
            :class="!checkBool ? 'active' : ''"
            >开始考试</el-button
          >
          <el-button @click="info">取消</el-button>
        </div>
        <p class="bottom-text">
          *此系统为西知网无纸化模拟考试系统，仅供考生模拟使用。若作为其他商业用途，本站保留最终解释权和追究侵权者法律责任的权利。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    return {
      play: false,
      time: 6 * 1000,
      timeData: {
        hours: 0,
        minutes: 0,
        seconds: 5,
      },
      checkBool: true,
      name: localStorage.name,
      titlename: localStorage.name,
    };
  },

  methods: {
    loginBtn() {
      this.play = true;
      this.$refs.countDown.start();
      // this.css[backgroundColor,'#ccc']
      this.checkBool = false;
    },
    info() {
      this.play = false;
      window.history.go(-1);
    },
    finish() {
      this.$router.push("/Index/Exam");
    },
  },
};
</script>

<style lang="less" scoped>
#Notice {
  width: 1180px;
  margin: 0 auto;
  height: 880px;
  padding-top: 20px;
  .notice_top {
    background: #fff;
    margin-bottom: 10px;
    padding: 0 30px;
    line-height: 70px;
    font-size: 12px;
    color: #666;
    display: flex;
    img {
      width: 240px;
      height: 80px;
    }
  }
  .testnotice {
    background: #fff;
    padding: 30px 30px 29px;
    h2 {
      text-align: center;
      font-weight: bold;
    }
    > div {
      background: #fafafa;
      margin: 30px 0;
      padding: 30px;
      border: 1px solid #eee;

      h4 {
        text-align: center;
        font-weight: bold;
      }
      p {
        color: #000;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
      }
      .time {
        margin: 40px 0 0 0;
        text-align: center;
        .colon {
          display: inline-block;
          margin: 0 4px;
          color: #ee0a24;
        }
        .block {
          display: inline-block;
          width: 22px;
          color: #fff;
          font-size: 12px;
          text-align: center;
          background-color: #ee0a24;
        }
      }
      .Btn {
        margin: 40px 0 auto;
        text-align: center;
        button {
          height: 36px;
          width: 120px;
        }
        .login-btn {
          margin-right: 54px;
          background: #df3833;
          color: #fff;
          border: 1px solid #df3833;
          pointer-events: auto;
          cursor: pointer;
          &.active {
            background-color: #ccc !important;
            border: #ccc;
          }
        }
      }
      .bottom-text {
        text-align: center;
        color: #df3833;
        margin-top: 20px;
      }
    }
  }
}
</style>